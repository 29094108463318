import * as Sentry from '@sentry/browser'
import SentryRRWeb from '@sentry/rrweb'

Sentry.init({
  dsn: 'https://77050d36be9440618cd723f76e584899@o265484.ingest.sentry.io/5454362',
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  integrations: [new SentryRRWeb()]
})

Sentry.setTag('domain', window.domain)
